export const CONNECTION_TYPE = 'connection_type';

export enum ConnectionType {
  INJECTED = 'INJECTED',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  WALLET_LINK = 'WALLET_LINK',
}

export enum CONNECTOR_ID {
  METAMASK = 'metamask',
  INJECTED = 'injected',
  WALLET_CONNECT = 'walletconnect',
  WALLET_LINK = 'walletlink',
}