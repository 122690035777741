import { DependencyList, EffectCallback, useEffect } from 'react';
import { useIsFirstRender } from './useIsFirstRender'

export const useEffectUpdate = (effect: EffectCallback, deps?: DependencyList): void => {
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      return effect();
    }
    return undefined;
  }, deps);
};

