import React, { FC, useMemo, useState } from 'react';
import { Button, Modal, Text } from 'sparkswap-uikit'
import styled from "styled-components";
import {
  migrationFarmsActions,
  MigrationInfoType, STAKE_STEP, useMigrationFarms,
  useMigrationFarmsFiltered,
  useStepper
} from 'contexts/MigrationFarmsContext'
import useMigrate from "../hooks/useMigrate";
import Stepper from './Stepper';
import ZeroStep from "./ZeroStep";
import STEPPER from "../constants";

const StylesButton = styled(Button)`
  margin-top: 16px;
`

interface Props {
  onDismiss?: () => void
}

const MigrationModal: FC<Props> = ({ onDismiss }) => {
  const { loading, unstake, stake } = useMigrate();
  const { currentStep, setCurrentStep } = useStepper();
  const activeMigrations = useMigrationFarmsFiltered();
  const migrationInfo = useMigrationFarms();
  const [currentMigration, setCurrentMigration] = useState<MigrationInfoType>()

  const activeMigration = useMemo(() => (
    activeMigrations && activeMigrations[0]
  ), [activeMigrations]);

  const handleUnstake = async () => {
    setCurrentMigration({ ...activeMigration }); // create copy
    await unstake(activeMigration);
    await migrationFarmsActions.updateMigrationInfo();
    setCurrentStep(STEPPER.TWO);
  }

  const handleStake = async () => {
    const migration = migrationInfo.find(item => (
      item.pid === currentMigration.pid
    ))
    if (!migration) return
    await stake(migration);
    await migrationFarmsActions.updateMigrationInfo();
    setCurrentMigration(undefined);
    if (activeMigration) {
      setCurrentStep(STEPPER.ZER0)
    } else {
      onDismiss()
    }
  }

  const isStakeStep = currentStep === STAKE_STEP;
  const hasMigration = activeMigration || isStakeStep;

  return (
    <Modal title="Farms are migrating" onDismiss={onDismiss}>
      {
        hasMigration && (
          <>
            {currentStep !== STEPPER.ZER0.toString() && (
              <Stepper step={Number(currentStep)} />
            )}
            {currentStep === STEPPER.ZER0.toString() && (
              <ZeroStep
                activeMigrations={activeMigrations}
                handleStep={setCurrentStep}
              />
            )}
            {
              currentStep === STEPPER.ONE.toString() && (
                <div>
                  <Text>Unstake {activeMigration.name}</Text>
                  <StylesButton
                    fullWidth
                    onClick={handleUnstake}
                    isLoading={loading}
                  >
                    Unstake
                  </StylesButton>
                </div>
              )
            }
            {
              isStakeStep && currentMigration && (
                <div>
                  <Text>Stake {currentMigration.name}</Text>
                  <StylesButton
                    fullWidth
                    onClick={handleStake}
                    isLoading={loading}
                  >
                    Stake
                  </StylesButton>
                </div>
              )
            }
          </>
        )
      }
    </Modal>
  )
}

export default MigrationModal;

