import React, { createContext, FC, PropsWithChildren } from 'react';
import useLocalStorage from 'react-localstorage-hook';
import { CONNECTION_TYPE, ConnectionType } from '../../config/constants/connectors.constants'


type ConnectionContextType = {
  connectionInStorage: ConnectionType;
  setConnectionInStorage: (value: string) => void;
};

export const ConnectionContext = createContext<ConnectionContextType | undefined>(undefined);

const ConnectionContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [connectionInStorage, setConnectionInStorage] = useLocalStorage(CONNECTION_TYPE);

  return (
    <ConnectionContext.Provider value={{ connectionInStorage, setConnectionInStorage }}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionContextProvider;