import { useEffect, useState, useCallback } from 'react'
import BigNumber from 'bignumber.js'

import { getCakeAddress } from 'utils/addressHelpers'
import { getDecimals, getTokenBalance } from 'utils/erc20'
import erc20 from 'config/abi/erc20.json'

import { useERC20 } from './useContract'
import { useFastRefresh } from '../contexts/RefreshContext/RefreshFastContext'
import { makeContract } from '../utils/contract'
import { useSlowRefresh } from '../contexts/RefreshContext/RefreshSlowContext'
import useWeb3 from './useWeb3'
import { BURN_ADDRESS } from '../config/constants'

const useTokenBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account, library, chainId } = useWeb3()
  const { fastRefresh } = useFastRefresh()


  const fetchBalance = useCallback(async () => {
    const tokenContract = makeContract(library, erc20, tokenAddress)
    const res = await getTokenBalance(tokenContract, account)
    setBalance(new BigNumber(res))
  },[account, library, tokenAddress])

  useEffect(() => {
    (async () => {
      if (account && tokenAddress && library) {
        await fetchBalance()
      }
    })()
  }, [account, fastRefresh, tokenAddress, library])

  return { balance, fetchBalance }
}

export const useTotalSupply = () => {
  const { library } = useWeb3()
  const { slowRefresh } = useSlowRefresh()
  const [totalSupply, setTotalSupply] = useState<BigNumber>(new BigNumber(0))

  useEffect(() => {
    async function fetchTotalSupply() {
      const cakeContract = makeContract(library, erc20, getCakeAddress())
      const supply = await cakeContract.methods.totalSupply().call()
      setTotalSupply(new BigNumber(supply))
    }

    if (library) fetchTotalSupply()
  }, [slowRefresh, library])

  return totalSupply
}

export const useBurnedBalance = (tokenAddress: string) => {
  const { library } = useWeb3()
  const [balance, setBalance] = useState(new BigNumber(0))
  const { slowRefresh } = useSlowRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const cakeContract = makeContract(library, erc20, tokenAddress)
      const bal = await cakeContract.methods.balanceOf(BURN_ADDRESS).call()
      setBalance(new BigNumber(bal))
    }

    if (library && tokenAddress) fetchBalance()
  }, [slowRefresh, library, tokenAddress])

  return balance
}

export const useDecimals = (tokenAddress: string) => {
  const { library } = useWeb3();
  const [decimals, setDecimals] = useState('')

  useEffect(() => {
    const fetch = async () => {
      const tokenContract = makeContract(library, erc20, tokenAddress)
      const decimalsResult = await getDecimals(tokenContract);
      setDecimals(decimalsResult);
    }

    if (library && tokenAddress) fetch()
  }, [library, tokenAddress])

  return decimals
}

export default useTokenBalance
