import React, { useState, useEffect, useContext } from 'react'

const INSTANT_INTERVAL = 1000

const RefreshInstantContext = React.createContext({ instant: 0 })

export const useInstantRefresh = () => useContext(RefreshInstantContext)

const RefreshInstantContextProvider = ({ children }) => {
  const [instant, setInstant] = useState(0)

  useEffect(() => {
    const interval = setInterval(async () => {
      setInstant((prev) => prev + 1)
    }, INSTANT_INTERVAL)
    return () => clearInterval(interval)
  }, [])


  return <RefreshInstantContext.Provider value={{ instant }}>{children}</RefreshInstantContext.Provider>
}

export { RefreshInstantContext, RefreshInstantContextProvider }