export default {
  spark: {
    369: '0x6386704cD6f7A584EA9D23cccA66aF7EBA5a727e',
    56: '0xf1825E71Ae0456B06bE9932Ba85Fef5a069e694d',
    97: '',
  },
  masterChef: {
    369: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    56: '0xa2d627974575117C4d17528e885e93b66B2F1288',
    97: '',
  },
  sparkler: {
    369: '0x44Ee223D0a9Eec269b1757685F438bddB311F1AE',
  },
  zapper: {
    369: '0xd2e0a1B3F6769bdF6D18b558cDD8E60d9eff2C62',
    56: '',
  },
  sparklerUtils: {
    369: '0x97FaD9b419218994f6dEa727858a8faB3988F05F',
  },
  wpls: {
    369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    369: '0xAA8BE262d44fa4D583d05635e08eAcEdbb311584',
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  dai: {
    369: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  sdai: {
    369: '0x30FCB23A906493371b1721C8feb8815804808D74',
    56: ''
  },
  usdex: {
    369: '0xaA2C47a35C1298795B5271490971Ec4874C8e53d',
    56: ''
  },
  emp: {
    369: '0x9231937aC31506B6913ac5fb1DB5a1C1AE83783A',
    56: '0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
  },
  weth: {
    369: '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C',
    56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  goat: {
    369: '0xF5D0140B4d53c9476DC1488BC6d8597d7393f074',
    56: ''
  },
  bro: {
    369: '0x401464296A7e0cd14D85AB6baF0Dc91B5ad5Ad1b',
    56: ''
  },
  bSpark: {
    369: '0xFcff9ddd271c8dc3A00d6F7e9C9cfE0FF80E3732',
    56: ''
  },
  nope: {
    369: '0x8D36123903f504eB81eEB832727af517c0db26bD',
    56: ''
  }
}
