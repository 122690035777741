import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'sparkswap-uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}


const GlobalStyle = createGlobalStyle`
    html {
        height: 100%;
    }
    body {
        height: 100%;
        background-color: ${({ theme }) => theme.colors.contrast};

        img {
            height: auto;
            max-width: 100%;
        }
    }
`

export default GlobalStyle
