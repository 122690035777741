import React, { FC, memo, PropsWithChildren } from 'react'
import styled from 'styled-components'
import Container from './Container'

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  flex: 1;
`

const Background = ({ withBg, left, right }) => {
  return (
    <>
      {withBg && (
        <>
          <BgLeft>
            <img src={left} alt="bg" width={950} />
          </BgLeft>
          <BgRight>
            <img src={right} alt="bg" width={950} />
          </BgRight>
        </>
      )}
    </>
  )
}

const BgLeft = styled.div`
  position: absolute;
  left: -50%;
  top: -150px;
  z-index: -1;
  pointer-events: none;
`

const BgRight = styled.div`
  position: absolute;
  right: -40%;
  top: 600px;
  z-index: -1;
  pointer-events: none;
`

interface Props extends PropsWithChildren {
  secondary?: boolean
  withBg?: boolean
}

const Page: FC<Props> = ({ children, secondary, withBg }) => {
  return (
    <Wrapper>
      {secondary ? (
        <PageWrapSecondary>
          <Background withBg={withBg} left="/images/bgLeft.png" right="/images/bgRight.png" />
          {children}
        </PageWrapSecondary>
      ) : (
        <PageWrap>
          <Background withBg={withBg} left="/images/bgLeft.png" right="/images/bgRight.png" />
          {children}
        </PageWrap>
      )}
    </Wrapper>
  )
}

Page.defaultProps = {
  secondary: false,
  withBg: false
}

const PageWrap = styled(Container)`
  margin: 0 auto;
  position: relative;
  padding-bottom: 100px;
  z-index: 19;
`

const PageWrapSecondary = styled(Container)`
  margin: 0 auto;
  position: relative;
  padding-bottom: 100px;
  z-index: 19;
  max-width: 1920px;
`

export default memo(Page)
