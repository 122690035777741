import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { ModalProvider } from 'sparkswap-uikit'
import { Connector } from '@web3-react/types'
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import store from 'state'
import ThemeContextProvider from 'contexts/ThemeContext'
import SlippageContext from 'contexts/SlippageContext'
import { BlockContextProvider } from 'contexts/BlockContext'
import Web3ContextProvider from './contexts/Web3Context'
import AuthorizationContextProvider from './contexts/AuthorizationContext'
import { RefreshSlowContextProvider } from './contexts/RefreshContext/RefreshSlowContext'
import { RefreshFastContextProvider } from './contexts/RefreshContext/RefreshFastContext'
import { RefreshInstantContextProvider } from './contexts/RefreshContext/RefreshInstantContext'
import { useOrderedConnections } from './hooks/useOrderedConnections'
import MigrationContext from './contexts/MigrationContext'
import MigrationFarmsContext from './contexts/MigrationFarmsContext'
import MigrationFarmsWrap from './views/MigrationFarmsWrap'


const Providers: FC<PropsWithChildren> = ({ children }) => {

  const connections = useOrderedConnections();

  const connectors: [Connector, Web3ReactHooks][] = connections.map(({ hooks, connector }) => [
    connector,
    hooks,
  ]);

  const [index, setIndex] = useState(0);

  useEffect(() => setIndex((ind) => ind + 1), [connections]);

  const key = useMemo(() => (
    connections.map((connection) => connection.getName()).join('-') + index
  ), [connections, index]);

  return (
    <SlippageContext>
      <Web3ReactProvider connectors={connectors} key={key + new Date()}>
        <Provider store={store}>
          <Web3ContextProvider>
            <AuthorizationContextProvider>
              <ThemeContextProvider>
                <BlockContextProvider>
                  <RefreshSlowContextProvider>
                    <RefreshFastContextProvider>
                      <RefreshInstantContextProvider>
                        <MigrationContext>
                          <MigrationFarmsContext>
                            <ModalProvider>
                              <MigrationFarmsWrap>{children}</MigrationFarmsWrap>
                            </ModalProvider>
                          </MigrationFarmsContext>
                        </MigrationContext>
                      </RefreshInstantContextProvider>
                    </RefreshFastContextProvider>
                  </RefreshSlowContextProvider>
                </BlockContextProvider>
              </ThemeContextProvider>
            </AuthorizationContextProvider>
          </Web3ContextProvider>
        </Provider>
      </Web3ReactProvider>
    </SlippageContext>
  )
}

export default Providers
