export default class Token {
  chainId: number;

  address: string;

  decimals: number;

  symbol: string;

  name: string;

  isLP: boolean;

  constructor(
    chainId: number,
    address: string,
    decimals: number,
    symbol: string,
    isLP?: boolean,
    name?: string,
  ) {
    this.chainId = chainId;
    this.address = address.toLowerCase();
    this.decimals = decimals;
    this.symbol = symbol;
    this.name = name;
    this.isLP = isLP;
  }
}
