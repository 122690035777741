import React, { FC } from 'react';
import { Button, Text } from "sparkswap-uikit";
import { MigrationInfoType } from 'contexts/MigrationFarmsContext';
import styled from 'styled-components';
import STEPPER from "../../constants";


const StylesButton = styled(Button)`
  margin-top: 16px;
`

interface Props {
  activeMigrations: MigrationInfoType[]
  handleStep: (value: STEPPER) => void
}
const ZeroStep: FC<Props> = ({ activeMigrations, handleStep }) => {

  const handleClick = () => {
    handleStep(STEPPER.ONE)
  }

  return (
    <div>

      <Text>Please follow these steps. This migration is <b><u>feeless</u></b></Text>
      <Text>and will have no negative impact on your LP.</Text>
      <Text style={{ marginTop: '4px', display: 'flex', gap: 4, }}>
        LPs to migrate:
        {activeMigrations.map(item => (
          <Text bold key={item.name}>{item.name}</Text>
        ))}
      </Text>
      <StylesButton fullWidth onClick={handleClick}>Start</StylesButton>
    </div>
  )
}

export default ZeroStep;
