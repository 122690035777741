import React, { createContext, FC, PropsWithChildren, useContext, useEffect } from 'react'

import useLocalStorage from 'react-localstorage-hook'

import { DEFAULT_SLIPPAGE_PARAM, SLIPPAGE_PARAM } from '../../config/constants/slippage'

type SlippageContextType = {
  slippage: string
}

export type slippageActionsType = {
  onChangeSlippage: (value: string) => void
}

const SlippageContext = createContext<SlippageContextType>(null)

export let slippageActions: slippageActionsType = null

export const useSlippage = (): SlippageContextType => useContext(SlippageContext)

const SlippageContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [slippageInStorage, setSlippageInStorage] = useLocalStorage(SLIPPAGE_PARAM)

  slippageActions = {
    onChangeSlippage: setSlippageInStorage,
  }

  useEffect(() => {
    if (!slippageInStorage) {
      setSlippageInStorage(DEFAULT_SLIPPAGE_PARAM)
    }
  }, [slippageInStorage])

  return <SlippageContext.Provider value={{ slippage: slippageInStorage }}>{children}</SlippageContext.Provider>
}

export default SlippageContextProvider
