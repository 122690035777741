import type { AddEthereumChainParameter } from '@web3-react/types'

interface BasicChainInformation {
  urls: string[]
  name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }

export enum CHAIN_ID {
  PULSE = 369,
}

export const CHAINS_RPC = {
  [CHAIN_ID.PULSE]: ['https://pulsechain.publicnode.com'],
};

const PULSE: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Pulse',
  symbol: 'PLS',
  decimals: 18,
}

// const BNB: AddEthereumChainParameter['nativeCurrency'] = {
//   name: 'Binance Coin',
//   symbol: 'BNB',
//   decimals: 18,
// }

export const DEFAULT_CHAIN_ID = CHAIN_ID.PULSE;

export const AVAILABLE_CHAINS_LIST = [
  CHAIN_ID.PULSE,
];

export const MAINNET_CHAINS: ChainConfig = {
  // 1: {
  //   urls: [getInfuraUrlFor('mainnet'), getAlchemyUrlFor('eth-mainnet'), 'https://cloudflare-eth.com'].filter(Boolean) as string[],
  //   name: 'Mainnet',
  // },
  // 10: {
  //   urls: [getInfuraUrlFor('optimism-mainnet'), 'https://mainnet.optimism.io'].filter(Boolean) as string[],
  //   name: 'Optimism',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://optimistic.etherscan.io'],
  // },
  // 42161: {
  //   urls: [getInfuraUrlFor('arbitrum-mainnet'), 'https://arb1.arbitrum.io/rpc'].filter(Boolean) as string[],
  //   name: 'Arbitrum One',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://arbiscan.io'],
  // },
  // 137: {
  //   urls: [getInfuraUrlFor('polygon-mainnet'), 'https://polygon-rpc.com'].filter(Boolean) as string[],
  //   name: 'Polygon Mainnet',
  //   nativeCurrency: MATIC,
  //   blockExplorerUrls: ['https://polygonscan.com'],
  // },
  369: {
    urls: ['https://rpc.pulsechain.com', 'https://pulsechain.publicnode.com'],
    name: 'PulseChain',
    nativeCurrency: PULSE,
    blockExplorerUrls: ['https://scan.pulsechain.com'],
  },
  // 56: {
  //   urls: ['https://bsc-dataseed.binance.org'],
  //   name: 'BNB Chain',
  //   nativeCurrency: BNB,
  //   blockExplorerUrls: ['https://bscscan.com'],
  // },
}

export const TESTNET_CHAINS: ChainConfig = {
  // 5: {
  //   urls: [getInfuraUrlFor('goerli')].filter(Boolean) as string[],
  //   name: 'Görli',
  // },
  // 420: {
  //   urls: [getInfuraUrlFor('optimism-goerli'), 'https://goerli.optimism.io'].filter(Boolean) as string[],
  //   name: 'Optimism Goerli',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://goerli-explorer.optimism.io'],
  // },
  // 421613: {
  //   urls: [getInfuraUrlFor('arbitrum-goerli'), 'https://goerli-rollup.arbitrum.io/rpc'].filter(Boolean) as string[],
  //   name: 'Arbitrum Goerli',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://testnet.arbiscan.io'],
  // },
  // 80001: {
  //   urls: [getInfuraUrlFor('polygon-mumbai')].filter(Boolean) as string[],
  //   name: 'Polygon Mumbai',
  //   nativeCurrency: MATIC,
  //   blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  // },
  // 44787: {
  //   urls: ['https://alfajores-forno.celo-testnet.org'],
  //   name: 'Celo Alfajores',
  //   nativeCurrency: CELO,
  //   blockExplorerUrls: ['https://alfajores-blockscout.celo-testnet.org'],
  // },
}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  }
  return chainId
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)
