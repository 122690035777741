import moment from 'moment';
import { DATE_FORMAT } from '../constants/date'


export const formatUnixTimestamp = (timestamp: string, format = DATE_FORMAT.YEAR_MONTH_DATE) =>
  moment.unix(parseInt(timestamp)).format(format);

export const timeSecondToDate = (timestamp: string | number): Date => (
    new Date(Number(timestamp) * 1000)
);
