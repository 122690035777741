import React, { Suspense, lazy, useMemo } from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { Buffer } from 'buffer';
import { useFetchPublicData } from 'state/hooks'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'


import { PATH_FARMS, PATH_FARMS_HISTORY, PATH_POOLS, PATH_SPARKLER } from './constants/routes.constants'
import Timer from './components/Timer'

const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Sparkler = lazy(() => import('./views/Sparkler'))
const NotFound = lazy(() => import('./views/NotFound'))

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

window.Buffer = window.Buffer || Buffer;

export const TIMER = '1716319913'

const App: React.FC = () => {
  const { hash } = window.location
  const suspend = useMemo(() => hash && hash.endsWith('sparkler'), [hash])
  useFetchPublicData(suspend)

  return (
    <Router>
      <Timer remainTime={TIMER} />
      <Menu>
        <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path={PATH_FARMS} element={<Farms />}>
                <Route path={PATH_FARMS_HISTORY} element={<Farms />} />
              </Route>
              <Route path={PATH_POOLS} element={<Farms tokenMode />} />
              <Route path={PATH_SPARKLER} element={<Sparkler />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
      </Menu>
    </Router>
  )
}

export default React.memo(App)
