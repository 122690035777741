import { useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useSelector, useDispatch } from 'react-redux'
import Web3 from 'web3'

import lpTokenAbi from 'config/abi/uni_v2_lp.json'
import { ENV_CHAIN_ID } from 'config/constants'
import { useDecimals } from 'hooks/useTokenBalance'
import { usePriceLP, useTotalBalance, useTotalBalanceLP } from 'hooks/useSparkler'
import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'
import { useSlowRefresh } from '../contexts/RefreshContext/RefreshSlowContext'
import { useFastRefresh } from '../contexts/RefreshContext/RefreshFastContext'
import useWeb3 from '../hooks/useWeb3'
import { makeContract } from '../utils/contract'

const ZERO = new BigNumber(0)

export const useFetchPublicData = (suspend: boolean) => {
  const dispatch = useDispatch()
  const { slowRefresh } = useSlowRefresh()
  useEffect(() => {
    if (!suspend) {
      dispatch(fetchFarmsPublicDataAsync())
    }
  }, [dispatch, suspend, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useFastRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 3 // BUSD-BNB LP
  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceWethBusd = (): BigNumber => {
  const pid = 6 // WETH / DAI
  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceCakeBusd = (): BigNumber => {
  const pid = 14 // CAKE-BNB LP
  const bnbPriceUSD = usePriceBnbBusd()
  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
}

export const useBalanceETH = (): BigNumber => {
  const [balance, setBalance] = useState<BigNumber>(ZERO)
  const { slowRefresh } = useSlowRefresh()
  const { account, library } = useWeb3()
  const web3 = new Web3(library.provider)

  useEffect(() => {
    const fetchBalance = async () => {
      setBalance(new BigNumber(await web3.eth.getBalance(account)))
    }

    if (account) {
      fetchBalance()
    }
  }, [account, slowRefresh])

  return balance
}

export const useDisplayBalanceUSD = (pid: number, amount: BigNumber): string => {
  const { isTokenOnly, tokenPriceVsQuote, quoteTokenSymbol, quoteTokenAdresses } = useFarmFromPid(pid);
  const plsPrice = usePriceBnbBusd();
  const sparkPrice = usePriceCakeBusd();
  const wethPrice = usePriceWethBusd();
  const lpInfo = useLpInfo(pid);

  const decimals0 = Number(useDecimals(lpInfo.token0));
  const decimals1 = Number(useDecimals(lpInfo.token1));

  return useMemo(() => {
    function calcToken() {
      switch (quoteTokenSymbol) {
        case QuoteToken.DAI:
          return `$${amount.times(tokenPriceVsQuote).div(1e18).toFormat(2)}`
        case QuoteToken.PLS:
          return `$${amount.times(tokenPriceVsQuote).times(plsPrice).div(1e18).toFormat(2)}`
        case QuoteToken.SPARK:
          return `$${amount.times(tokenPriceVsQuote).times(sparkPrice).div(1e18).toFormat(2)}`
        case QuoteToken.WETH:
          return `$${amount.times(tokenPriceVsQuote).times(wethPrice).div(1e18).toFormat(2)}`
        default:
          return `${amount.times(tokenPriceVsQuote).div(1e18).toFormat(2)} ${String(quoteTokenSymbol)}`
      }
    }

    function calcLP() {
      switch (quoteTokenSymbol) {
        case QuoteToken.DAI:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount.times(tokenPriceVsQuote).times(lpInfo.reserve0).times(2).div(lpInfo.totalSupply).div(10 ** decimals0).toFormat(2)}`
            : `$${amount.times(tokenPriceVsQuote).times(lpInfo.reserve1).times(2).div(lpInfo.totalSupply).div(10 ** decimals1).toFormat(2)}`
        case QuoteToken.PLS:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount.times(plsPrice).times(tokenPriceVsQuote).times(lpInfo.reserve0).times(2).div(lpInfo.totalSupply).div(10 ** decimals0).toFormat(2)}`
            : `$${amount.times(plsPrice).times(tokenPriceVsQuote).times(lpInfo.reserve1).times(2).div(lpInfo.totalSupply).div(10 ** decimals1).toFormat(2)}`
        case QuoteToken.WETH:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount.times(wethPrice).times(tokenPriceVsQuote).times(lpInfo.reserve0).times(2).div(lpInfo.totalSupply).div(10 ** decimals0).toFormat(2)}`
            : `$${amount.times(wethPrice).times(tokenPriceVsQuote).times(lpInfo.reserve1).times(2).div(lpInfo.totalSupply).div(10 ** decimals1).toFormat(2)}`
        case QuoteToken.SPARK:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount.times(sparkPrice).times(tokenPriceVsQuote).times(lpInfo.reserve0).times(2).div(lpInfo.totalSupply).div(10 ** decimals0).toFormat(2)}`
            : `$${amount.times(sparkPrice).times(tokenPriceVsQuote).times(lpInfo.reserve1).times(2).div(lpInfo.totalSupply).div(10 ** decimals1).toFormat(2)}`
        default:
          return `${amount.times(tokenPriceVsQuote).times(lpInfo.reserve0).times(2).div(lpInfo.totalSupply).div(10 ** decimals0).toFormat(2)} ${String(quoteTokenSymbol)}`
      }
    }

    if (plsPrice && wethPrice && sparkPrice && quoteTokenSymbol && lpInfo.token1 && lpInfo.token0 && quoteTokenAdresses && decimals0 && decimals1) {
      return isTokenOnly ? calcToken() : calcLP()
    }

    return '$0'

  }, [
    isTokenOnly,
    amount,
    quoteTokenSymbol,
    tokenPriceVsQuote,
    plsPrice,
    wethPrice,
    sparkPrice,
    lpInfo,
    quoteTokenAdresses,
    decimals0,
    decimals1
  ])
}

export interface LpInfo {
  totalSupply: BigNumber;
  reserve0: BigNumber;
  reserve1: BigNumber;
  token0: string;
  token1: string;
}

export const useLpInfo = (pid: number): LpInfo => {
  const { isTokenOnly, lpAddresses } = useFarmFromPid(pid);
  const lpAddress = useMemo(()=> (
    lpAddresses[ENV_CHAIN_ID]
  ), [lpAddresses])

  const { slowRefresh } = useSlowRefresh();
  const { library } = useWeb3();


  const [info, setInfo] = useState({
    totalSupply: ZERO,
    reserve0: ZERO,
    reserve1: ZERO,
    token0: '',
    token1: ''
  } as LpInfo);

  useEffect(() => {
    const fetch = async () => {
      const lpContract = makeContract(library, lpTokenAbi, lpAddress)
      try {
        const [totalSupply, reserves, token0, token1] = await Promise.all([
          lpContract.methods.totalSupply().call(),
          lpContract.methods.getReserves().call(),
          lpContract.methods.token0().call(),
          lpContract.methods.token1().call()
        ]);

        setInfo({
          totalSupply: new BigNumber(totalSupply.toString()),
          reserve0: new BigNumber(reserves._reserve0.toString()),
          reserve1: new BigNumber(reserves._reserve1.toString()),
          token0,
          token1
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (!isTokenOnly && library && lpAddress) {
      fetch()
    }
  }, [isTokenOnly, library, slowRefresh, lpAddress]);

  return info;
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()
  const wethPrice = usePriceWethBusd()
  const totalBalance = useTotalBalance()
  const totalBalanceLP = useTotalBalanceLP()
  const priceLP = usePriceLP()

  const sparklerTVL = useMemo(() => {
    if (!totalBalance || !totalBalanceLP || !priceLP) return ZERO
    return totalBalance.times(cakePrice).plus(totalBalanceLP.times(priceLP)).div(1e18)
  }, [totalBalance, totalBalanceLP, cakePrice, priceLP])

  const value = useMemo(() => {
    let totalValue = sparklerTVL ?? ZERO
    if (farms.length > 0) {
      totalValue = farms.reduce((accumulator, farm) => {
        if (farm.lpTotalInQuoteToken) {
          let val
          switch (farm.quoteTokenSymbol) {
            case QuoteToken.PLS:
              val = bnbPrice.times(farm.lpTotalInQuoteToken)
              break
            case QuoteToken.SPARK:
              val = cakePrice.times(farm.lpTotalInQuoteToken)
              break
            case QuoteToken.WETH:
              val = wethPrice.times(farm.lpTotalInQuoteToken)
              break
            default:
              val = farm.lpTotalInQuoteToken
          }
          return accumulator.plus(val)
        }
        return accumulator
      }, totalValue)
    }
    return totalValue
  }, [farms, bnbPrice, cakePrice, wethPrice, sparklerTVL])
  return value
}
