import { NavEntry, SocialEntry }  from 'sparkswap-uikit'

import {
  EXCHANGE_URL,
  PATH_SPARKLER,
  PERPS_URL,
  VAULTS_URL,
  MARKETPLACE_URL,
} from '../../constants/routes.constants'

export const links: NavEntry[] = [
  {
    label: 'Trade',
    items: [
      {
        label: 'Exchange',
        href: `${EXCHANGE_URL}/#/swap`,
      },
      {
        label: 'Liquidity',
        href: `${EXCHANGE_URL}/#/pool`,
      }
    ],
  },
  {
    label: 'Spark404',
    href: `${EXCHANGE_URL}/#/spark404`
  },
  {
    label: 'Bridge',
    href: `${EXCHANGE_URL}/#/bridge`,
  },
  {
    label: 'Farms',
    href: '/farms',
  },
  {
    label: 'Vaults',
    href: VAULTS_URL,
    openTab: true
  },
  {
    label: 'Sparkler',
    href: PATH_SPARKLER,
  },
  {
    label: 'Perpetuals',
    items: [
      {
        label: 'Perpetuals',
        href: `${PERPS_URL}`,
      },
      {
        label: 'SLP Vault',
        href: `${PERPS_URL}/vault`,
      },
      {
        label: 'Stats',
        href: `${PERPS_URL}/stats`,
      },
      {
        label: 'Daily Trades',
        href: `${PERPS_URL}/daily-trades`,
      },
    ],
  },
  {
    label: 'Marketplace',
    initialOpenState: true,
    items: [

      {
        label: 'Collection',
        href: `${MARKETPLACE_URL}/collections/1`,
      },
      {
        label: 'Dashboard',
        href: `${MARKETPLACE_URL}/dashboard`,
      },
    ]
  },
];

export const socials: SocialEntry[] = [
  {
    label: "Twitter",
    icon: "TwitterIcon",
    href: "https://x.com/sparkswap_",
  },
  {
    label: "Youtube",
    icon: "YoutubeIcon",
    href: "https://www.youtube.com/@EmpMoney/videos",
  },
  {
    label: "Discord",
    icon: "DiscordIcon",
    href: "https://discord.com/invite/sparkswap",
  },
  {
    label: "Telegram",
    icon: "TelegramIcon",
    href: "https://t.me/spark_swap",
  },
];

export const docs: { title: string, href: string }[] = [
  {
    title: 'Docs',
    href: 'https://empmoneyv2.gitbook.io/sparkswap/about/sparkswap-whitepaper',
  },
  {
    title: 'Chart',
    href: 'https://dexscreener.com/pulsechain/0x33208439e1B28B1d6fCfbB6334e9950027Ee3B52',
  },
  {
    title: 'Audit',
    href: 'https://fleek.ipfs.io/ipfs/bafybeiawdbrvhwwpvmuc6agcxsxpjrigv3bzjtfmwei2zftrjsf3gv4zlm',
  },
];



