import React, { FC, createContext, useEffect, useState, useCallback, useContext, PropsWithChildren } from 'react'
import { getContract } from 'utils/web3'
import useWeb3 from '../hooks/useWeb3'
import { MIGRATION_TOKENS } from "../config/constants";
import erc20Abi from '../config/abi/erc20.json';
import masterchefAbi from '../config/abi/masterchef.json';

export type MigrationInfoType = {
  decimal: number,
  farmBalance: string,
  lpAddress: string,
  token0: string,
  token1: string,
  newLpAddress: string,
  lpBalance: string,
  newLpBalance: string,
  masterChef: string,
  name: string,
  pid: string,
  migrator: string,
  newPid: string,
}

const MigrationContext = createContext<MigrationInfoType[]>(null);

export type migrationActionsType = {
  updateMigrationInfo: () => Promise<void>
}

export let migrationActions: migrationActionsType = null;

export const useMigration = (): MigrationInfoType[] => useContext(MigrationContext);

const MigrationContextProvider: FC<PropsWithChildren> = ({ children}) => {
  const [migrationInfo, setMigrationInfo] = useState<MigrationInfoType[]>(null);
  const { account } = useWeb3();

  const updateMigrationInfo = useCallback(async () => {
    const result = await Promise.all(MIGRATION_TOKENS.map(async item => {
      const lpContract = getContract(erc20Abi, item.lpAddress);
      const newlpContract = getContract(erc20Abi, item.newLpAddress);
      const masterchefContract = getContract(masterchefAbi, item.masterChef);

      const lpBalance = await lpContract.methods.balanceOf(account).call();
      const newLpBalance = await newlpContract.methods.balanceOf(account).call();
      const userInfo = await masterchefContract.methods.userInfo(item.pid, account).call();

      return {
        ...item,
        lpBalance,
        newLpBalance,
        farmBalance: userInfo.amount,
      };
    }));
    setMigrationInfo(result);
  },[account])

  migrationActions = {
    updateMigrationInfo,
  };

  useEffect(() => {
    if (account) {
      (async () => {
        await updateMigrationInfo();
      })()
    }
  }, [updateMigrationInfo, account]);

  return (
    <MigrationContext.Provider value={migrationInfo}>
      {children}
    </MigrationContext.Provider>
  )
}

export default MigrationContextProvider
