import { AbiItem } from 'web3-utils'
import {
  getMasterChefAddress,
  getCakeAddress,
  getLotteryAddress,
  getLotteryTicketAddress,
  getSparklerAddress,
  getSparklerUtilsAddress,
  getZapperAddress } from 'utils/addressHelpers'
import { ENV_CHAIN_ID, poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'
import ifo from 'config/abi/ifo.json'
import erc20 from 'config/abi/erc20.json'
import weth from 'config/abi/weth.json'
import rabbitmintingfarm from 'config/abi/rabbitmintingfarm.json'
import contracts from 'config/constants/contracts'
import pancakeRabbits from 'config/abi/pancakeRabbits.json'
import lottery from 'config/abi/lottery.json'
import lotteryTicket from 'config/abi/lotteryNft.json'
import masterChef from 'config/abi/masterchef.json'
import sparkler from 'config/abi/sparkler.json'
import zapper from 'config/abi/zapper.json'
import sparklerUtils from 'config/abi/sparklerUtils.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'

import useWeb3 from './useWeb3'
import { makeContract } from '../utils/contract'
import logo from '../components/Svg/Icons/Logo'


export const useIfoContract = (address: string) => {
  const ifoAbi = ifo as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, ifoAbi, address)
}

export const useERC20 = (address: string) => {
  const { library } = useWeb3()

  return makeContract(library, erc20, address)
}

export const useWETH = () => {
  const { library } = useWeb3()
  const wethAbi = weth as unknown as AbiItem

  return makeContract(library, wethAbi, contracts.wpls[ENV_CHAIN_ID])
}

export const useCake = () => {
  return useERC20(getCakeAddress())
}

export const useRabbitMintingFarm = (address: string) => {
  const rabbitMintingFarmAbi = rabbitmintingfarm as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, rabbitMintingFarmAbi, address)
}

export const usePancakeRabbits = (address: string) => {
  const pancakeRabbitsAbi = pancakeRabbits as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, pancakeRabbitsAbi, address)
}

export const useLottery = () => {
  const abi = lottery as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, abi, getLotteryAddress())
}

export const useLotteryTicket = () => {
  const abi = lotteryTicket as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, abi, getLotteryTicketAddress())
}

export const useMasterchef = () => {
  const abi = masterChef as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, abi, getMasterChefAddress())
}

export const useSousChef = (id) => {
  const { library } = useWeb3()
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const rawAbi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  const abi = rawAbi as unknown as AbiItem

  return makeContract(library, abi, config.contractAddress[process.env.REACT_APP_CHAIN_ID])
}

export const useSparkler = () => {
  const { library } = useWeb3()
  return makeContract(library, sparkler, getSparklerAddress())
}

export const useZapperContract = () => {
  const abi = zapper as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, abi, getZapperAddress())
}

export const useSparklerUtils = () => {
  const abi = sparklerUtils as unknown as AbiItem
  const { library } = useWeb3()

  return makeContract(library, abi, getSparklerUtilsAddress())
}
