import React, {
  FC,
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react'
import BigNumber from 'bignumber.js';
import { getContract } from 'utils/web3'
import useWeb3 from '../hooks/useWeb3'
import { MIGRATION_FARMS } from "../config/constants";
import erc20Abi from '../config/abi/erc20.json';
import masterchefAbi from '../config/abi/masterchef.json';
import STEPPER from '../views/MigrationWrap/constants';


export type MigrationInfoType = {
  name: string;
  pid: string,
  masterChef: string,
  newMasterChef: string,
  lpToken: string,
  lpBalance: string,
  farmBalance: string,
}

export const STAKE_STEP = STEPPER.TWO.toString();

const MigrationFarmsContext = createContext<MigrationInfoType[]>(null);

export type migrationActionsType = {
  updateMigrationInfo: () => Promise<void>
}

export let migrationFarmsActions: migrationActionsType = null;

export const useMigrationFarms = (): MigrationInfoType[] => useContext(MigrationFarmsContext);

export const useStepper = () => {
  const [storedStep, setStoredStep] = useState(STEPPER.ZER0.toString())

  const setCurrentStep = useCallback((step: STEPPER) => {
    setStoredStep(step.toString());
  }, [])

  return {
    currentStep: storedStep,
    setCurrentStep,
  }
}

export const useMigrationFarmsFiltered = (): MigrationInfoType[] => {
  const migrationInfo = useMigrationFarms();
  const { currentStep } = useStepper();

  return useMemo(() => (
    migrationInfo ? migrationInfo.filter(item =>
      !new BigNumber(item.farmBalance).isZero() ||
      (currentStep === STAKE_STEP && !new BigNumber(item.lpBalance).isZero())
    ) : []
  ), [migrationInfo, currentStep]);
}

const MigrationFarmsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [migrationInfo, setMigrationInfo] = useState<MigrationInfoType[]>(null);
  const { account } = useWeb3();

  const updateMigrationInfo = useCallback(async () => {
    const result = await Promise.all(MIGRATION_FARMS.map(async item => {
      const lpContract = getContract(erc20Abi, item.lpToken);
      const masterchefContract = getContract(masterchefAbi, item.masterChef);

      const lpBalance = await lpContract.methods.balanceOf(account).call();
      const userInfo = await masterchefContract.methods.userInfo(item.pid, account).call();

      return {
        ...item,
        lpBalance,
        farmBalance: userInfo.amount,
      };
    }));
    setMigrationInfo(result);
  }, [account])

  migrationFarmsActions = {
    updateMigrationInfo,
  };

  useEffect(() => {
    if (account) {
      (async () => {
        await updateMigrationInfo();
      })()
    }
  }, [updateMigrationInfo, account]);

  return (
    <MigrationFarmsContext.Provider value={migrationInfo}>
      {children}
    </MigrationFarmsContext.Provider>
  )
}

export default MigrationFarmsContextProvider
