import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 21,
    risk: 5,
    lpSymbol: 'NOPE-sDAI LP',
    lpAddresses: {
      97: '',
      369: '0x34D08F0Fd753dCFbaC0b129436C35cF0E3F1607F',
    },
    tokenSymbol: 'NOPE',
    tokenAddresses: {
      97: '',
      369: '0x8D36123903f504eB81eEB832727af517c0db26bD',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.sdai,
    reverseImage: true,
    v2: true,
  },
  {
    pid: 20,
    risk: 5,
    lpSymbol: 'NOPE-SPARK LP',
    lpAddresses: {
      97: '',
      369: '0x6ebb0E3B4E82aBd7cC717aB5c8a9f94f652E1B99',
    },
    tokenSymbol: 'NOPE',
    tokenAddresses: {
      97: '',
      369: '0x8D36123903f504eB81eEB832727af517c0db26bD',
    },
    quoteTokenSymbol: QuoteToken.SPARK,
    quoteTokenAdresses: contracts.spark,
    reverseImage: true,
    v2: true,
  },
  {
    pid: 19,
    risk: 5,
    lpSymbol: 'bSpark-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x8B951EB284ec0D80e94bf19874740B59E7392275',
    },
    tokenSymbol: 'bSpark',
    tokenAddresses: {
      97: '',
      369: '0xFcff9ddd271c8dc3A00d6F7e9C9cfE0FF80E3732',
    },
    quoteTokenSymbol: QuoteToken.PLS,
    quoteTokenAdresses: contracts.wpls,
    reverseImage: true,
    v2: true,
  },
  {
    pid: 14,
    risk: 5,
    lpSymbol: 'SPARK-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x33208439e1B28B1d6fCfbB6334e9950027Ee3B52',
    },
    tokenSymbol: 'SPARK',
    tokenAddresses: {
      97: '',
      369: '0x6386704cD6f7A584EA9D23cccA66aF7EBA5a727e',
    },
    quoteTokenSymbol: QuoteToken.PLS,
    quoteTokenAdresses: contracts.wpls,
    v2: true,
  },
  {
    pid: 16,
    risk: 5,
    lpSymbol: 'SPARK-sDAI LP',
    lpAddresses: {
      97: '',
      369: '0x9095D464A29Abd1B840C1C5205FB602ae5b011FF',
    },
    tokenSymbol: 'SPARK',
    tokenAddresses: {
      97: '',
      369: '0x6386704cD6f7A584EA9D23cccA66aF7EBA5a727e',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.sdai,
    reverseImage: true,
    v2: true,
  },
  {
    pid: 13,
    risk: 3,
    lpSymbol: 'sDAI-DAI',
    lpAddresses: {
      97: '',
      56: '',
      369: '0xf32B9398a7277609772F328Fc2005D7DA5420E77',
    },
    tokenSymbol: 'sDAI',
    tokenAddresses: {
      97: '',
      56: '',
      369: contracts.sdai[369],
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    rehypothecated: false,
    v2: true,
  },
  {
    pid: 18,
    risk: 5,
    lpSymbol: 'BRO-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x627B0e2E692e37Ef75bb7a9F0348fA893cb75949',
    },
    tokenSymbol: 'BRO',
    tokenAddresses: {
      97: '',
      369: '0x401464296A7e0cd14D85AB6baF0Dc91B5ad5Ad1b',
    },
    quoteTokenSymbol: QuoteToken.PLS,
    quoteTokenAdresses: contracts.wpls,
    v2: true,
  },
  {
    pid: 17,
    risk: 3,
    lpSymbol: 'ETH-DAI',
    lpAddresses: {
      97: '',
      56: '',
      369: '0x52729266e890De1F9F83c462f5C9fbDeAe78dbF9',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      56: '',
      369: contracts.weth[369],
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    rehypothecated: false,
    v2: true,
  },
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'SPARK-PLS LP',
    lpAddresses: {
      97: '',
      56: '0x1de8c2e9eeb6e2722c13900fdbe571e6e720819c',
      369: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
    },
    tokenSymbol: 'SPARK',
    tokenAddresses: {
      97: '',
      56: '0xAcb32a082980dB3B1CB3E8A7B67faa44AE415128',
      369: '0x6386704cD6f7A584EA9D23cccA66aF7EBA5a727e',
    },
    disabled: true,
    quoteTokenSymbol: QuoteToken.PLS,
    quoteTokenAdresses: contracts.wpls,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'PLSX-PLS LP',
    lpAddresses: {
      97: '',
      56: '0x1de8c2e9eeb6e2722c13900fdbe571e6e720819c',
      369: '0x1b45b9148791d3a104184cd5dfe5ce57193a3ee9',
    },
    tokenSymbol: 'PLSX',
    tokenAddresses: {
      97: '',
      56: '0xAcb32a082980dB3B1CB3E8A7B67faa44AE415128',
      369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.PLS,
    quoteTokenAdresses: contracts.wpls,
    rehypothecated: true,
  },
  {
    pid: 10,
    risk: 3,
    lpSymbol: 'SPARK-GOAT LP',
    lpAddresses: {
      56: '',
      369: '0xE8339d9eb0580D6A3E8AaA4544812BA357949D79',
    },
    tokenSymbol: 'GOAT',
    tokenAddresses: {
      56: '',
      369: '0xF5D0140B4d53c9476DC1488BC6d8597d7393f074',
    },
    quoteTokenSymbol: QuoteToken.SPARK,
    quoteTokenAdresses: contracts.spark,
    rehypothecated: false,
    disabled: true,
    v2: true,
  },
  {
    pid: 3,
    risk: 3,
    lpSymbol: 'DAI-PLS LP',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
      369: '0xe56043671df55de5cdf8459710433c10324de0ae',
    },
    tokenSymbol: 'PLS',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    reverseImage: true,
    rehypothecated: true,
  },
  {
    pid: 8,
    risk: 3,
    lpSymbol: 'USDC-PLS LP',
    lpAddresses: {
      97: '',
      56: '',
      369: '0x6753560538ECa67617A9Ce605178F788bE7E524E',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '',
      369: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
    },
    quoteTokenSymbol: QuoteToken.PLS,
    quoteTokenAdresses: contracts.wpls,
    rehypothecated: true,
  },
  {
    pid: 7,
    risk: 3,
    lpSymbol: 'HEX-PLS LP',
    lpAddresses: {
      97: '',
      56: '',
      369: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65',
    },
    tokenSymbol: 'HEX',
    tokenAddresses: {
      97: '',
      56: '',
      369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
    },
    quoteTokenSymbol: QuoteToken.PLS,
    quoteTokenAdresses: contracts.wpls,
    rehypothecated: true,
  },
  {
    pid: 9,
    risk: 3,
    lpSymbol: 'EMP-ETH LP',
    lpAddresses: {
      97: '',
      56: '',
      369: '0xb631E17a9ED67741d4397c4cb877eF0ba3bD63b9',
    },
    tokenSymbol: 'EMP',
    tokenAddresses: {
      97: '',
      56: '',
      369: '0x9231937aC31506B6913ac5fb1DB5a1C1AE83783A',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    rehypothecated: false,
    reverseImage: true,
    imageSizes: [48, 48],
    disabled: true,
    v2: true,
  },
  {
    pid: 12,
    risk: 3,
    lpSymbol: 'EMP-ETH LP',
    lpAddresses: {
      97: '',
      56: '',
      369: '0xd3300F0Bd587f0B9A77b62F74EA26FcA1086618b',
    },
    tokenSymbol: 'EMP',
    tokenAddresses: {
      97: '',
      56: '',
      369: '0x9231937aC31506B6913ac5fb1DB5a1C1AE83783A',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    rehypothecated: false,
    reverseImage: true,
    imageSizes: [48, 48],
    v2: true,
  },
  {
    pid: 15,
    risk: 3,
    lpSymbol: 'sDAI-USDEX+',
    lpAddresses: {
      97: '',
      56: '',
      369: '0xf3a8a528c6300Cf1E70762c3695B9C60B5a79e15',
    },
    tokenSymbol: 'USDEX+',
    tokenAddresses: {
      97: '',
      56: '',
      369: contracts.usdex[369],
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.sdai,
    rehypothecated: false,
    v2: true,
  },
  {
    pid: 11,
    risk: 3,
    lpSymbol: 'SPARK-GOAT LP',
    lpAddresses: {
      97: '',
      56: '',
      369: '0x207288D32A108dfA89733B6e7354da0a8e768973',
    },
    tokenSymbol: 'GOAT',
    tokenAddresses: {
      97: '',
      56: '',
      369: '0xF5D0140B4d53c9476DC1488BC6d8597d7393f074',
    },
    quoteTokenSymbol: QuoteToken.SPARK,
    quoteTokenAdresses: contracts.spark,
    rehypothecated: false,
    v2: true,
  },
  {
    pid: 2,
    risk: 3,
    lpSymbol: 'DAI-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
      369: '0x3225e3b0d3c6b97ec9848f7b40bb3030e5497709',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      369: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    reverseImage: true,
    disabled: true,
  },
  {
    pid: 4,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WPLS',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // BNB-BUSD LP
      369: '0xe56043671df55de5cdf8459710433c10324de0ae', // PLS-DAI LP
    },
    tokenSymbol: 'WPLS',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    disabled: true,
  },
  {
    pid: 5,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // BNB-BUSD LP
      369: '0xe56043671df55de5cdf8459710433c10324de0ae',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      369: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    disabled: true,
  },
  {
    pid: 6,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // BNB-BUSD LP
      369: '0x2bb9baa3092ce864390bf3e687abf72be19e03dc', // ETH-DAI LP
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      369: '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    disabled: true,
  },
  // {
  //   pid: 0,
  //   risk: 5,
  //   lpSymbol: 'SPARK-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //   },
  //   tokenSymbol: 'SPARK',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 1,
  //   risk: 5,
  //   lpSymbol: 'SPARK-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xd1b59d11316e87c3a0a069e80f590ba35cd8d8d3',
  //   },
  //   tokenSymbol: 'SPARK',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 3,
  //   risk: 3,
  //   lpSymbol: 'BNB-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //   },
  //   tokenSymbol: 'BNB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 3,
  //   risk: 1,
  //   lpSymbol: 'USDT-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd',
  //   },
  //   tokenSymbol: 'USDT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x55d398326f99059ff775485246999027b3197955',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 4,
  //   risk: 2,
  //   lpSymbol: 'BTCB-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x7561eee90e24f3b348e1087a005f78b4c8453524',
  //   },
  //   tokenSymbol: 'BTCB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 5,
  //   risk: 2,
  //   lpSymbol: 'ETH-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
  //   },
  //   tokenSymbol: 'ETH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 6,
  //   risk: 1,
  //   lpSymbol: 'DAI-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc',
  //   },
  //   tokenSymbol: 'DAI',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 7,
  //   risk: 1,
  //   lpSymbol: 'USDC-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680dd100e4b394bda26a59dd5c119a391e747d18',
  //   },
  //   tokenSymbol: 'USDC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 9,
  //   risk: 3,
  //   lpSymbol: 'DOT-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xbcd62661a6b1ded703585d3af7d7649ef4dcdb5c',
  //   },
  //   tokenSymbol: 'DOT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 10,
  //   risk: 4,
  //   lpSymbol: 'CAKE-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458',
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 11,
  //   risk: 4,
  //   lpSymbol: 'CAKE-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa527a61703d82139f8a06bc30097cc9caa2df5a6',
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 12,
  //   risk: 5,
  //   isTokenOnly: true,
  //   lpSymbol: 'SPARK',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019', // SPARK-BUSD LP
  //   },
  //   tokenSymbol: 'SPARK',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 13,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'BUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019', // SPARK-BUSD LP (BUSD-BUSD will ignore)
  //   },
  //   tokenSymbol: 'BUSD',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 14,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'WBNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // BNB-BUSD LP
  //   },
  //   tokenSymbol: 'WBNB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 15,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd', // USDT-BUSD LP
  //   },
  //   tokenSymbol: 'USDT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x55d398326f99059ff775485246999027b3197955',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 16,
  //   risk: 2,
  //   isTokenOnly: true,
  //   lpSymbol: 'BTCB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xb8875e207ee8096a929d543c9981c9586992eacb', // BTCB-BUSD LP
  //   },
  //   tokenSymbol: 'BTCB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 17,
  //   risk: 2,
  //   isTokenOnly: true,
  //   lpSymbol: 'ETH',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xd9a0d1f5e02de2403f68bb71a15f8847a854b494', // ETH-BUSD LP
  //   },
  //   tokenSymbol: 'ETH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 18,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'DAI',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc', // DAI-BUSD LP
  //   },
  //   tokenSymbol: 'DAI',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 19,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDC',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680dd100e4b394bda26a59dd5c119a391e747d18', // USDC-BUSD LP
  //   },
  //   tokenSymbol: 'USDC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 20,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'DOT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x54c1ec2f543966953f2f7564692606ea7d5a184e', // DOT-BUSD LP
  //   },
  //   tokenSymbol: 'DOT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 21,
  //   risk: 4,
  //   isTokenOnly: true,
  //   lpSymbol: 'CAKE',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458', // CAKE-BUSD LP
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 22,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'BSCX',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa32a983a64ce21834221aa0ad1f1533907553136', // BSCX-BUSD LP
  //   },
  //   tokenSymbol: 'BSCX',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 23,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'AUTO',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x4d0228ebeb39f6d2f29ba528e2d15fc9121ead56', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'AUTO',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
]

export default farms
