import React, { FC, PropsWithChildren, useEffect, useRef } from 'react'
import { useModal } from 'sparkswap-uikit';
import { useMigrationFarmsFiltered } from 'contexts/MigrationFarmsContext'
import MigrationModal from "./MigrationModal";

const MigrationFarmsWrap: FC<PropsWithChildren> = ({ children}) => {
  const activeMigrations = useMigrationFarmsFiltered();
  const isModalOpen = useRef(false);

  const [onPresentMigration] = useModal(
    <MigrationModal />
  )

  useEffect(() => {
    if (activeMigrations) {
      if (!isModalOpen.current && activeMigrations.length > 0) {
        onPresentMigration();
        isModalOpen.current = true
      }
    }
  }, [activeMigrations, onPresentMigration]);

  return (
    <>
      {children}
    </>
  )
}

export default MigrationFarmsWrap;
