import React, {FC} from 'react';
import styled from 'styled-components';
import STEPPER from "../../constants";

const Step = styled.div<{ isDone: boolean } >`
  border-radius: 50%;
  background: ${(({isDone}) => isDone ? '#BC0FC8' : '#E6A4EA')};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`

const Line = styled.div<{ isDone: boolean } >`
  height: 3px;
  background: ${(({isDone}) => isDone ? '#BC0FC8' : '#E6A4EA')};
  width: 80%;
  border-radius: 10px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 24px;
`

interface Props {
  step: STEPPER
}


const STEPS = [STEPPER.ONE, STEPPER.TWO];
const Stepper: FC<Props> = ({ step}) => {

  return (
    <Flex>
      {
        STEPS.map((item) => (
          <React.Fragment key={String(item)}>
            <Step isDone={step >= item}>{item}</Step>
            {
              item !== STEPPER.TWO && (
                <Line isDone={item >= STEPPER.ONE}/>
              )
            }
          </React.Fragment>
        ))
      }
    </Flex>
  )
}

export default Stepper;
