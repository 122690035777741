import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'sparkswap-uikit'
import Page from './layout/Page'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const PageLoader: React.FC = () => {
  return (
    <Page>
      <Wrapper>
        <Spinner />
      </Wrapper>
    </Page>
  )
}

export default PageLoader
