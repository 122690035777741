import { useCallback, useMemo, useState } from 'react'
import { AbiItem } from "web3-utils";
import Web3 from 'web3'
import { MaxUint256 } from "@ethersproject/constants";


import { MigrationInfoType } from 'contexts/MigrationFarmsContext'
import masterchefAbi from "../../../config/abi/masterchef.json";
import erc20fAbi from "../../../config/abi/erc20.json";
import useWeb3 from '../../../hooks/useWeb3'


const GAS_FACTOR = 1.1;
const BLOCK_GAS_LIMIT = 30000000;

const upscaleGas = (gas: number) => {
  return Math.min(Math.floor(gas * GAS_FACTOR), BLOCK_GAS_LIMIT);
}

const useMigrate = () => {
  const [loading, setLoading] = useState(false);
  const { account, library } = useWeb3()
  const web3 = useMemo(() => {
    return new Web3(library.provider);
  }, [library.provider]);

  const unstake = useCallback(async (migrateItem: MigrationInfoType) => {
    setLoading(true);
    const abi = masterchefAbi as unknown as AbiItem
    const masterchefContract = new web3.eth.Contract(abi, migrateItem.masterChef);
    try {
      const gasEstimate = await masterchefContract
        .methods
        .withdraw(migrateItem.pid, migrateItem.farmBalance)
        .estimateGas({ from: account });
      await masterchefContract
        .methods
        .withdraw(migrateItem.pid, migrateItem.farmBalance)
        .send({ from: account, gas: upscaleGas(gasEstimate) })
        .on('transactionHash', (tx) => {
          return tx.transactionHash
        })
    } catch (e) {
      throw new Error('Failed Transaction');
    } finally {
      setLoading(false);
    }
  }, [web3, account])

  const stake = useCallback(async (migrateItem: MigrationInfoType) => {
    setLoading(true);
    const abi = masterchefAbi as unknown as AbiItem;
    const lpAbi = erc20fAbi as unknown as AbiItem;
    const masterchefContract = new web3.eth.Contract(abi, migrateItem.newMasterChef);
    const lpContract = new web3.eth.Contract(lpAbi, migrateItem.lpToken);
    try {
      await lpContract
        .methods
        .approve(migrateItem.newMasterChef, MaxUint256.toString())
        .send({ from: account });
      const gasEstimate = await masterchefContract
        .methods
        .deposit(migrateItem.pid, migrateItem.lpBalance)
        .estimateGas({ from: account });
      await masterchefContract
        .methods
        .deposit(migrateItem.pid, migrateItem.lpBalance)
        .send({ from: account, gas: upscaleGas(gasEstimate) })
        .on('transactionHash', (tx) => {
          return tx.transactionHash
        })
    } catch (e) {
      throw new Error('Failed Transaction');
    } finally {
      setLoading(false);
    }
  }, [web3, account])


  return { loading, unstake, stake };
}

export default useMigrate;
