import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { shuffle } from 'lodash'
import { useERC20, useSparkler, useSparklerUtils } from 'hooks/useContract'
import contracts from 'config/constants/contracts'
import { approve } from 'utils/callHelpers'
import { ENV_CHAIN_ID } from 'config/constants'
import { useFarmFromPid, useLpInfo, usePriceBnbBusd } from 'state/hooks'
import { getTokenBalance } from 'utils/erc20'
import { fetchFarmsPublicDataAsync } from 'state/actions'
import { AddressZero } from '@ethersproject/constants'
import { ZERO } from 'utils'
import useWeb3 from './useWeb3'
import { useSlowRefresh } from '../contexts/RefreshContext/RefreshSlowContext'
import { useFastRefresh } from '../contexts/RefreshContext/RefreshFastContext'
import { toBN } from '../utils/formaters'
import { makeContract } from '../utils/contract'
import sparkler from '../config/abi/sparkler.json'
import { getSparklerAddress, getSparklerUtilsAddress } from '../utils/addressHelpers'
import erc20 from '../config/abi/erc20.json'
import sparklerUtils from '../config/abi/sparklerUtils.json'

export const DAY_SECONDS = 86400;
export const MAX_LOCK_DAYS = 365 * 10;
export const MIN_LOCK_DAYS = 60 / 86400;
const ONE_ETHER = new BigNumber(1).times(1e18);

export const usePriceLP = (): BigNumber => {
  const dispatch = useDispatch()
  const plsPrice = usePriceBnbBusd();
  const { tokenPriceVsQuote, quoteTokenAdresses } = useFarmFromPid(14);
  const { token1, reserve0, reserve1, totalSupply } = useLpInfo(14);
  const [hasFetched, setHasFetched] = useState(false)

  return useMemo(() => {
    if (plsPrice && token1 && reserve0 && reserve1 && totalSupply && tokenPriceVsQuote && quoteTokenAdresses) {
      return token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
        ? ONE_ETHER.times(plsPrice).times(tokenPriceVsQuote).times(reserve0).times(2).div(totalSupply).div(1e18)
        : ONE_ETHER.times(plsPrice).times(tokenPriceVsQuote).times(reserve1).times(2).div(totalSupply).div(1e18)
    }

    if (!hasFetched) {
      setHasFetched(true)
      dispatch(fetchFarmsPublicDataAsync())
    }
    return ZERO;
  }, [tokenPriceVsQuote, quoteTokenAdresses, plsPrice, token1, reserve0, reserve1, totalSupply, hasFetched])
}

export const useTotalBalanceLP = () => {
  const { library } = useWeb3()
  const [balance, setBalance] = useState(ZERO)
  const { slowRefresh } = useSlowRefresh()
  const { lpAddresses } = useFarmFromPid(14);

  useEffect(() => {
    const fetchBalance = async () => {
      const tokenContract = makeContract(library, erc20, lpAddresses[ENV_CHAIN_ID])
      try {
        const res = await getTokenBalance(tokenContract, getSparklerAddress())
        setBalance(new BigNumber(res))
      } catch (e) {
        console.error('balanceOf', e);
      }
    }

    if (library && lpAddresses) {
      fetchBalance()
    }
  }, [slowRefresh, library, lpAddresses])

  return balance
}

export const useRewardsPerSecond = (): BigNumber => {
  const [rewardsPerSecond, setRewardsPerSecond] = useState(new BigNumber(0))
  const sparklerContract = useSparkler()
  const { slowRefresh } = useSlowRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        setRewardsPerSecond(
          new BigNumber(await sparklerContract.methods.rewardsPerSecond().call())
        )
      } catch (e) {
        console.error('rewardsPerSecond', e);
      }
    }

    if (sparklerContract) {
      fetch()
    }

  }, [sparklerContract, slowRefresh])

  return rewardsPerSecond
}

export const useRewardsPerSecondAverage = (): BigNumber => {
  const [rewardsPerSecond, setRewardsPerSecond] = useState(new BigNumber(0))
  const { library } = useWeb3()
  const { slowRefresh } = useSlowRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerUtilsContract = makeContract(library, sparklerUtils, getSparklerUtilsAddress())
        const rewards = await sparklerUtilsContract.methods.getAverageRewardsPerSecond().call();
        setRewardsPerSecond(toBN(rewards))
      } catch (e) {
        console.error('getAverageRewardsPerSecond', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [library, slowRefresh])

  return rewardsPerSecond
}

export const usePastDaysRewards = (): BigNumber => {
  const [rewards, setRewards] = useState(new BigNumber(0))
  const { library } = useWeb3()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerUtilsContract = makeContract(library, sparklerUtils, getSparklerUtilsAddress())
        const rewardsValue = await sparklerUtilsContract.methods.getPastDaysRewards().call();
        setRewards(toBN(rewardsValue))
      } catch (e) {
        console.error('getPastDaysRewards', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [library])

  return rewards
}

export const useLockTimeBonusDenominator = (): BigNumber => {
  const [result, setResult] = useState(new BigNumber(0))
  const { library } = useWeb3()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract = makeContract(library, sparkler, getSparklerAddress())
        const rewards = await sparklerContract.methods.lockTimeBonusDenominator().call();
        setResult(toBN(rewards))
      } catch (e) {
        console.error('lockTimeBonusDenominator', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [library])

  return result
}

export const useShareMultiplierBp = (): BigNumber => {
  const [result, setResult] = useState(new BigNumber(0))
  const { library } = useWeb3()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract = makeContract(library, sparkler, getSparklerAddress())
        const res = await sparklerContract.methods.shareMultiplierBp().call()
        setResult(toBN(res))
      } catch (e) {
        console.error('shareMultiplierBp', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [library])

  return result
}

export const useTotalShares = (): BigNumber => {
  const [totalShares, setTotalShares] = useState(new BigNumber(0))
  const { library } = useWeb3()
  const { slowRefresh } = useSlowRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const totalSharesValue = await sparklerContract.methods.total_shares().call();
        setTotalShares(toBN(totalSharesValue))
      } catch (e) {
        console.error('total_shares', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [library, slowRefresh])

  return totalShares
}

export const useTotalClaimed = (): BigNumber => {
  const [totalClaimed, setTotalClaimed] = useState(new BigNumber(0))
  const { library } = useWeb3()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const totalClaimedValue = await sparklerContract.methods.total_claimed().call();
        setTotalClaimed(toBN(totalClaimedValue))
      } catch (e) {
        console.error('total_claimed', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [library])

  return totalClaimed
}

export const useTotalBalance = (): BigNumber => {
  const { library } = useWeb3()
  const [totalBalance, setTotalBalance] = useState(new BigNumber(0))
  const { slowRefresh } = useSlowRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const balance = await sparklerContract.methods.total_balance().call();
        setTotalBalance(toBN(balance))
      } catch (e) {
        console.error('total_balance', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [slowRefresh, library])

  return totalBalance
}

export const useTotalUsers = (): BigNumber => {
  const [totalUsers, setTotalUsers] = useState(new BigNumber(0))
  const { library } = useWeb3()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const users = await sparklerContract.methods.total_users().call()
        setTotalUsers(toBN(users))
      } catch (e) {
        console.error('total_users', e);
      }
    }

    if (library) {
      fetch()
    }

  }, [library])

  return totalUsers
}

export const useTotalRewardsUser = (): BigNumber => {
  const [rewards, setRewards] = useState(new BigNumber(0))
  const { library, account } = useWeb3()
  const { fastRefresh } = useFastRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const reward = await sparklerContract.methods.getTotalRewards(account).call()
        setRewards(toBN(reward))
      } catch (e) {
        console.error('getTotalRewards', e);
      }
    }

    if (library && account) {
      fetch()
    }

  }, [library, account, fastRefresh])

  return rewards
}

export const useRewardsList = (): string[] => {
  const [rewards, setRewards] = useState([] as string[])
  const { account, library } = useWeb3()
  const { slowRefresh } = useSlowRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const reward =   await sparklerContract.methods.listRewards(account).call() as string[]
        setRewards(reward)
      } catch (e) {
        console.error('listRewards', e);
      }
    }

    if (library && account) {
      fetch()
    }

  }, [library, account, slowRefresh])

  return rewards
}

interface ExpiredStake {
  address: string;
  ids: string[];
}

export interface Bounty {
  address: string;
  bountyReward: BigNumber;
}

export const useExpiredStakeBounty = (): Bounty => {
  const [bounty, setBounty] = useState({ address: AddressZero, bountyReward: ZERO } as Bounty)
  const { library } = useWeb3()
  const totalUsers = useTotalUsers()
  const [batchSize, ] = useState(500);
  const [tried, setTried] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const sparklerUtils2= makeContract(library, sparklerUtils, getSparklerUtilsAddress())
        setTried(true);
        const batches = [] as number[];
        const lastUserIndex = totalUsers.toNumber();
        for (let i = 0; i <= lastUserIndex; i += batchSize) {
          batches.push(i);
        }
        
        const startIndex = batches[Math.floor(Math.random() * batches.length)];
        const endIndex = startIndex + batchSize > lastUserIndex ? lastUserIndex : startIndex + batchSize;
        const data = await sparklerContract.methods.listExpiredStakes(startIndex, endIndex).call();

        if (data[0] && data[1] && data[1].length > 0) {
          const expiredStakes = data[0].map((x, i) => ({ address: String(x), ids: data[1][i] } as ExpiredStake)).filter((x: ExpiredStake) => x && x.ids.length > 0 && x.address && x.address !== AddressZero) as ExpiredStake[];
          const shuffledExpiredStakes = shuffle(expiredStakes);
          const expiredStakeBounty = await sparklerUtils2.methods.findExpiredStakeBounty(shuffledExpiredStakes.map((x) => x.address), shuffledExpiredStakes.map((x) => x.ids)).call();
          setBounty({
            address: expiredStakeBounty[0],
            bountyReward: new BigNumber(expiredStakeBounty[1])
          } as Bounty)
        }
      } catch (e) {
        console.error('listExpiredStakes && findExpiredStakeBounty', e);
      }
    }

    if (library && totalUsers.gt(0) && !tried) {
      fetch()
    }

  }, [library, totalUsers, batchSize, tried])

  return bounty
}

export const useAllowance = (): BigNumber => {
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const { account, library } = useWeb3()
  const { fastRefresh } = useFastRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparkContract = makeContract(library, erc20, contracts.spark[ENV_CHAIN_ID])
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const allowanceValue = await sparkContract.methods.allowance(account, sparklerContract.options.address).call()
        setAllowance(toBN(allowanceValue))
      } catch (e) {
        console.error('allowance', e);
      }
    }

    if (library && account) {
      fetch()
    }

  }, [library, account, fastRefresh])

  return allowance
}

/* eslint-disable camelcase */
export interface Stake {
  startTime: string;
  endTime: string;
  amount: string;
  claimed: string;
  shares: string;
  accPoints: string;
}

export const useUserStakesAll = (): Stake[] => {
  const [stakes, setStakes] = useState([] as Stake[])
  const { account, library } = useWeb3()
  const { fastRefresh } = useFastRefresh()

  useEffect(() => {
    const fetch = async () => {
      try {
        const sparklerContract= makeContract(library, sparkler, getSparklerAddress())
        const stake = await sparklerContract.methods.getStakesAll(account).call() as Stake[]
        setStakes(stake)
      } catch (e) {
        console.error('getStakesAll', e);
      }
    }

    if (library && account) {
      fetch()
    }

  }, [library, account, fastRefresh])

  return stakes
}

export const useApprove = () => {
  const { account } = useWeb3()
  const sparklerContract = useSparkler()
  const sparkContract = useERC20(contracts.spark[ENV_CHAIN_ID])

  const handleApprove = useCallback(async () => {
    try {
      return await approve(sparkContract, sparklerContract, account)
    } catch (e) {
      console.error('approve', e);
      return false
    }
  }, [account, sparkContract, sparklerContract])

  return { onApprove: handleApprove }
}

export const useStake = () => {
  const { account } = useWeb3()
  const sparklerContract = useSparkler()

  const handleStake = useCallback(
    async (amount: string, lockDays: string) => {
      try {
        const lockTime = Number(lockDays) * 86400
        const txHash = await sparklerContract.methods
          .stake(new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(0), lockTime.toFixed(0))
          .send({ from: account })
          .on('transactionHash', (tx) => {
            return tx.transactionHash
          })
        console.info(txHash)
        return txHash
      } catch (e) {
        console.error('stake', e);
        return false
      }
    },
    [account, sparklerContract],
  )

  return { onStake: handleStake }
}

export const useUnstake = () => {
  const { account } = useWeb3()
  const sparklerContract = useSparkler()

  const handleUnstake = useCallback(
    async (id: string) => {
      try {
        const txHash = await sparklerContract.methods
          .unstake(id)
          .send({ from: account })
          .on('transactionHash', (tx) => {
            return tx.transactionHash
          })
        console.info(txHash)
        return txHash
      } catch (e) {
        console.error('unstake', e);
        return false
      }
    },
    [account, sparklerContract],
  )

  return { onUnstake: handleUnstake }
}

export const useBounty = () => {
  const { account } = useWeb3()
  const sparklerContract = useSparkler()

  const handleBounty = useCallback(
    async (address: string) => {
      try {
        const txHash = await sparklerContract.methods
          .goodAccounting(address)
          .send({ from: account })
          .on('transactionHash', (tx) => {
            return tx.transactionHash
          })
        console.info(txHash)
        return txHash
      } catch (e) {
        console.error('goodAccounting', e);
        return false
      }
    },
    [account, sparklerContract],
  )

  return { onBounty: handleBounty }
}

export const useHarvest = (id: number) => {
  const { account } = useWeb3()
  const sparklerContract = useSparkler()

  const handleHarvest = useCallback(async () => {
    const txHash = await sparklerContract.methods
      .claim(account, id, true)
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
    return txHash
  }, [account, id, sparklerContract])

  return { onReward: handleHarvest }
}

export const useHarvestAll = () => {
  const { account } = useWeb3()
  const sparklerContract = useSparkler()

  const handleHarvest = useCallback(async () => {
    const txHash = await sparklerContract.methods
      .claimAll()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
    return txHash
  }, [account, sparklerContract])

  return { onReward: handleHarvest }
}
