import React from 'react'
import { toBN } from '../../utils/formaters'

export enum SLIPPAGE_ERROR {
  INVALID_INPUT = 'INVALID_INPUT',
  RISKY_LOW = 'RISKY_LOW',
  RISKY_HIGH = 'RISKY_HIGH',
}

export const SLIPPAGE_MESSAGE_ERROR = {
  [SLIPPAGE_ERROR.INVALID_INPUT]: 'Enter a valid slippage percentage',
  [SLIPPAGE_ERROR.RISKY_HIGH]: 'Your transaction may fail',
  [SLIPPAGE_ERROR.RISKY_LOW]: 'Your transaction may be frontrun',
}

export const SLIPPAGE_TOLERANCE_OPTIONS = [
  {
    value: '0.1',
  },
  {
    value: '0.5',
  },
  {
    value: '1',
  },
]

export const MIN_VALID_VALUE = 0

export const MAX_VALID_VALUE = 49.99

export const MIN_SUCCESS_VALID_VALUE = 0.5

export const MAX_DECIMALS_REGEX = /^\d*\.?\d{0,2}$/

export const INPUT_REGEX = RegExp('^\\d*(?:\\\\[.])?\\d*$')

export const DECIMAL_PATTERN_REGEX = /^(?!0\d)\d{0,2}(\.\d{1,2})?$/;
export const SPECIAL_CHARACTERS_REGEX = /[.*+?^${}()|[\]\\]/g;

export const DEFAULT_SLIPPAGE_PARAM = toBN(0.995)

export const SLIPPAGE_PARAM = 'slippage_param'

export const PERCENT_ICON = (
  <svg width="19" height="20" fill="none" >
    <path
      d="M4.16 15V12.856L15.12 3.56L15.632 3.48V5.608L4.72 14.888L4.16 15ZM4.832 8.92C4.43733 8.92 4.08533 8.82933 3.776 8.648C3.46667 8.46667 3.22133 8.22133 3.04 7.912C2.85867 7.592 2.768 7.24 2.768 6.856V5.608C2.768 5.21333 2.85867 4.86133 3.04 4.552C3.22133 4.24267 3.46667 3.99733 3.776 3.816C4.08533 3.63467 4.43733 3.544 4.832 3.544H6.24C6.63467 3.544 6.98667 3.63467 7.296 3.816C7.60533 3.99733 7.85067 4.24267 8.032 4.552C8.21333 4.86133 8.304 5.21333 8.304 5.608V6.856C8.304 7.24 8.21333 7.592 8.032 7.912C7.85067 8.22133 7.60533 8.46667 7.296 8.648C6.98667 8.82933 6.63467 8.92 6.24 8.92H4.832ZM4.56 7.384H6.496C6.58133 7.384 6.65067 7.35733 6.704 7.304C6.75733 7.25067 6.784 7.18667 6.784 7.112V5.352C6.784 5.26667 6.75733 5.19733 6.704 5.144C6.65067 5.09067 6.58133 5.064 6.496 5.064H4.56C4.48533 5.064 4.42133 5.09067 4.368 5.144C4.31467 5.19733 4.288 5.26667 4.288 5.352V7.112C4.288 7.18667 4.31467 7.25067 4.368 7.304C4.42133 7.35733 4.48533 7.384 4.56 7.384ZM13.248 14.936C12.864 14.936 12.5173 14.8453 12.208 14.664C11.8987 14.4827 11.6533 14.2373 11.472 13.928C11.2907 13.6187 11.2 13.2667 11.2 12.872V11.624C11.2 11.2293 11.2907 10.8773 11.472 10.568C11.6533 10.2587 11.8987 10.0133 12.208 9.832C12.5173 9.65067 12.864 9.56 13.248 9.56H14.656C15.0507 9.56 15.4027 9.65067 15.712 9.832C16.0213 10.0133 16.2667 10.2587 16.448 10.568C16.6293 10.8773 16.72 11.2293 16.72 11.624V12.872C16.72 13.2667 16.6293 13.6187 16.448 13.928C16.2667 14.2373 16.0213 14.4827 15.712 14.664C15.4027 14.8453 15.0507 14.936 14.656 14.936H13.248ZM12.992 13.416H14.928C15.0027 13.416 15.0667 13.3893 15.12 13.336C15.1733 13.2827 15.2 13.2187 15.2 13.144V11.352C15.2 11.2773 15.1733 11.2133 15.12 11.16C15.0667 11.1067 15.0027 11.08 14.928 11.08H12.992C12.9173 11.08 12.8533 11.1067 12.8 11.16C12.7467 11.2133 12.72 11.2773 12.72 11.352V13.144C12.72 13.2187 12.7467 13.2827 12.8 13.336C12.8533 13.3893 12.9173 13.416 12.992 13.416Z"
      fill="#D501BB" />
  </svg>
)
