import farmsConfig from './farms'
import Token from "../../types/token";
//
const communityFarms = farmsConfig.filter((farm) => farm.isCommunity).map((farm) => farm.tokenSymbol)
const ENV_CHAIN_ID = String(process.env.REACT_APP_CHAIN_ID) || '369'

export { farmsConfig, communityFarms, ENV_CHAIN_ID }
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'

export const MIGRATOR_ADDRESS = '0x0ADE7610BBB5dB15f1A792B85652daFEb39d4A50'

export const MIGRATION_TOKENS = [
  {
    name: 'SPARK-GOAT LP',
    lpAddress: '0xE8339d9eb0580D6A3E8AaA4544812BA357949D79',
    token0: '0x6386704cd6f7a584ea9d23ccca66af7eba5a727e',
    token1: '0xf5d0140b4d53c9476dc1488bc6d8597d7393f074',
    pid: '10',
    newPid: '11',
    decimal: 18,
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newLpAddress: '0x207288D32A108dfA89733B6e7354da0a8e768973',
    migrator: '0x0ADE7610BBB5dB15f1A792B85652daFEb39d4A50',
  },
  {
    name: 'EMP-ETH LP',
    lpAddress: '0xb631E17a9ED67741d4397c4cb877eF0ba3bD63b9',
    token0: '0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c',
    token1: '0x9231937ac31506b6913ac5fb1db5a1c1ae83783a',
    pid: '9',
    newPid: '12',
    decimal: 18,
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newLpAddress: '0xd3300F0Bd587f0B9A77b62F74EA26FcA1086618b',
    migrator: '0x0ADE7610BBB5dB15f1A792B85652daFEb39d4A50',
  },
  {
    name: 'SPARK/PLS',
    lpAddress: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
    token0: '0x6386704cd6f7a584ea9d23ccca66af7eba5a727e',
    token1: '0xa1077a294dde1b09bb078844df40758a5d0f9a27',
    pid: '0',
    newPid: '14',
    decimal: 18,
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newLpAddress: '0x33208439e1B28B1d6fCfbB6334e9950027Ee3B52',
    migrator: '0xB2c3036e1727F004E70a18582e9b9ca7D4BbfA43',
  }
]

export const MIGRATION_FARMS = [
  // {
  //   name: 'SPARK-PLS LP',
  //   pid: '0',
  //   masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
  //   newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
  //   lpToken: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
  // },
  {
    name: 'PLSX-PLS LP',
    pid: '1',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9',
  },
  {
    name: 'DAI-USDC LP',
    pid: '2',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0x3225E3B0D3C6b97EC9848f7B40bb3030E5497709',
  },
  {
    name: 'DAI-PLS LP',
    pid: '3',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
  },
  // {
  //   name: 'WPLS',
  //   pid: '4',
  //   masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
  //   newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
  //   lpToken: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
  // },
  // {
  //   name: 'DAI',
  //   pid: '5',
  //   masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
  //   newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
  //   lpToken: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
  // },
  // {
  //   name: 'WETH',
  //   pid: '6',
  //   masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
  //   newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
  //   lpToken: '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C',
  // },
  {
    name: 'HEX-PLS LP',
    pid: '7',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65',
  },
  {
    name: 'USDC-PLS LP',
    pid: '8',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0x6753560538ECa67617A9Ce605178F788bE7E524E',
  },
  // {
  //   name: 'EMP-ETH LP',
  //   pid: '9',
  //   masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
  //   newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
  //   lpToken: '0xb631E17a9ED67741d4397c4cb877eF0ba3bD63b9',
  // },
  // {
  //   name: 'SPARK-GOAT LP',
  //   pid: '10',
  //   masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
  //   newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
  //   lpToken: '0xE8339d9eb0580D6A3E8AaA4544812BA357949D79',
  // },
  {
    name: 'SPARK-GOAT LP',
    pid: '11',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0x207288D32A108dfA89733B6e7354da0a8e768973',
  },
  {
    name: 'EMP-ETH LP',
    pid: '12',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0xd3300F0Bd587f0B9A77b62F74EA26FcA1086618b',
  },
  {
    name: 'sDAI-DAI',
    pid: '13',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0xf32B9398a7277609772F328Fc2005D7DA5420E77',
  },
  {
    name: 'SPARK-PLS LP',
    pid: '14',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0x33208439e1B28B1d6fCfbB6334e9950027Ee3B52',
  },
  {
    name: 'sDAI-USDEX+',
    pid: '15',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0xf3a8a528c6300Cf1E70762c3695B9C60B5a79e15',
  },
  {
    name: 'SPARK-sDAI LP',
    pid: '16',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0x9095D464A29Abd1B840C1C5205FB602ae5b011FF',
  },
  {
    name: 'ETH-DAI',
    pid: '17',
    masterChef: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    newMasterChef: '0x63c2a0083861F8C496A0A29BD8BA223E1180664e',
    lpToken: '0x52729266e890De1F9F83c462f5C9fbDeAe78dbF9',
  },
]
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD';
export const NATIVE_TOKEN = new Token(
  Number(ENV_CHAIN_ID),
  ZERO_ADDRESS,
  18,
  'PLS',
)


export const LP_DECIMALS = 18;
