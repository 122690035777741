import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  box: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    flexDirection: 'column',
  },
  timerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  banner: {
    background: 'linear-gradient(90deg, rgba(32, 55, 251, 1) 0%, rgba(221, 19, 177, 1) 100%)',
    color: '#fff',
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px 0',

    '@media (max-width: 640px)': {
      flexDirection: 'column',
    },
  },
  heading: {
    textAlign: 'center',
  }
}));

export default useStyles;
