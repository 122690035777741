import { ENV_CHAIN_ID } from 'config/constants'
import addresses from 'config/constants/contracts'

export const getCakeAddress = () => {
  return addresses.spark[ENV_CHAIN_ID]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[ENV_CHAIN_ID]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[ENV_CHAIN_ID]
}
export const getWbnbAddress = () => {
  return addresses.wpls[ENV_CHAIN_ID]
}
export const getLotteryAddress = () => {
  return addresses.lottery[ENV_CHAIN_ID]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[ENV_CHAIN_ID]
}

export const getSparklerAddress = () => {
  return addresses.sparkler[ENV_CHAIN_ID]
}

export const getZapperAddress = () => {
  return addresses.zapper[ENV_CHAIN_ID]
}

export const getSparklerUtilsAddress = () => {
  return addresses.sparklerUtils[ENV_CHAIN_ID]
}

